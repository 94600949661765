.App {
  font-family: 'Sombra-Regular', sans-serif;
  font-size: 26px;
  overflow: hidden;
  max-width: 1700px;
  margin: 0 auto;
}

@font-face {
  font-family: 'Sombra-Regular';
  src: local('Sombra'), url('./font/Sombra-Regular.woff2') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Sombra-Black';
  src: local('Sombra'), url('./font/Sombra-Black.woff2') format('truetype');
  font-display: swap;
}
