html{
  scroll-behavior: smooth;
  background-color: #cedfea;
}

*{
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: Sombra-Regular, Sombra-Black, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Sombra-Regular, Sombra-Black, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headroom {
  display: flex;
  justify-items: center;
  height: 80px;
  background-color: #8cbefa;
  color: #fff;
  transition: top 0.2s ease-in-out;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  z-index: 99;
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}
.headroom--scrolled {
  transform: translateY(-120%);
  transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-120%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}
